.skeleton-carousel__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 150px auto;
  max-width: 100%;
}

.skeleton-carousel__inner {
  display: flex;
  gap: 20px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
}

.skeleton-carousel__slide {
  width: 400px;
  height: 300px;
  background-color: var(--color-dark-grey);
  border-radius: 8px;
  animation: skeleton-loading 1.2s infinite;
}

.skeleton-carousel__controls {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.skeleton-carousel__arrow {
  width: 25px;
  height: 25px;
  background-color: var(--color-dark-grey);
  border-radius: 50%;
  animation: skeleton-loading 1.2s infinite;
}

.skeleton-carousel__dots {
  display: flex;
  gap: 10px;
  margin: 0 15px;
}

.skeleton-carousel__dot {
  width: 10px;
  height: 10px;
  background-color: var(--color-dark-grey);
  border-radius: 50%;
  animation: skeleton-loading 2s infinite ease-in;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--color-dark-grey);
  }
  50% {
    background-color: #212121;
  }
  100% {
    background-color: var(--color-dark-grey);
  }
}
